import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import modalConstant from '../../../../assets/modal-constants.json';
import { ApplicationService } from '../../../../services/application.service';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { DeleteAlertModalComponent } from '../modals/delete-alert-modal/delete-alert-modal.component';
import { SuccesspopupComponent } from '../modals/successpopup/successpopup.component';
import { RolesService } from 'src/services/roles.service';
import { ConstService } from 'src/services/const.service';

const PAGE_SIZE = 10;
@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AllUserComponent implements OnInit, AfterViewInit {
  @Input() teamUser;
  @Input() teamId;
  @Input() role;
  @Input() userIdExist: any = [];
  loading;
  allUsers = [];
  allUsersList = [];
  selected = [];
  filterShow = false;
  filterStr = '';
  page = { totalElements: 0, pageNumber: 0, size: PAGE_SIZE };
  @Input() asModal = false;
  @Output() userData = new EventEmitter();
  tableVisible = false;
  userType: string;
  disableEdit = true;
  useUserList = [];
  filterColumns = [
    { prop: 'userName', name: 'Member', selected: true },
    { prop: 'role', name: 'Role', selected: true },
    { prop: 'employer', name: 'Employer', selected: true },
    { prop: 'executionTeams', name: 'Execution team', selected: true },
    { prop: 'email', name: 'Email', selected: true },
    { prop: 'phone', name: 'Phone', selected: true }
  ];
  ff = [];
  userCount: any = [];
  temp = [];
  isSystemAdmin = false;
  userSystemroleRoles: any = {};
  showButton = false;
  hide_icon = false;
  search = false;
  userId;
  isExecutionView = false;
  currentTimeout: any = null;
  userRolesObject: any;
  constructor(private route: Router, private appSvc: ApplicationService,
    private modalService: NgbModal, config: NgbModalConfig, public rolesService: RolesService, 
    private constService : ConstService) {
    config.backdrop = 'static';
  }

  ngOnInit() {
    if (!this.teamId) {
      this.teamId = this.constService.emptyGuid;
    }
    this.userId = sessionStorage.getItem('UserId');
    this.isSystemAdmin = this.rolesService.isSystemAdmin();
    this.userSystemroleRoles = this.appSvc.getUserPrivilages();
    this.userRolesObject = this.appSvc.getUserPrivilages();
    if (this.userRolesObject.roles.toLowerCase() === this.rolesService.SYSTEM_MANAGER || this.userRolesObject.roles.toLowerCase() === this.rolesService.SYSTEM_SUPPORT
      || this.userRolesObject.roles.toLowerCase() === this.rolesService.ADMIN) {
      this.isExecutionView = false;
    } else {
      this.isExecutionView = true;
    }

    if (this.teamUser === true) {
      this.loading = true;
      this.appSvc.getAllUsersInTeam(this.teamId, this.page.size, this.page.pageNumber).subscribe({next:res => {
        this.userCount = res;
        if (this.userCount.length > 0) {
          this.userData.emit(true);
        } else {
          this.userData.emit(false);
        }
        this.loading = false;
        this.page.totalElements = res.totalElements;
        this.allUsersList = res.data;
        this.allUsers = res.data;
        const arr2 = this.allUsers.map(x => ({ ...x, key: false }));
        this.allUsers = arr2;
        this.allUsers.forEach(element => {
          this.userIdExist.forEach(element2 => {
            if (element.userId === element2.userId) {
              element.key = true;
            }
          });
        });
        this.useUserList = res.data;
        this.selected = [];
        setTimeout(() => {
          this.tableVisible = true;
        }, 0);
      }, error: () => {
        this.loading = false;
      }});
    } else if (this.role === 'ABBManager') {
      this.loading = true;
      this.appSvc.getAllProjectUsers(this.teamId, this.page.size, this.page.pageNumber).subscribe({next:res => {
        if (res.data.length > 0) {
          this.userData.emit(true);
        } else {
          this.userData.emit(false);
        }
        this.loading = false;
        this.page.totalElements = res.totalElements;
        this.allUsersList = res.data;
        this.allUsers = res.data;
        this.useUserList = res.data;
        this.selected = [];
        setTimeout(() => {
          this.tableVisible = true;
        }, 0);
      }, error: () => {
        this.loading = false;
      }});
    } else if (this.role === 'projectManagers') {
      this.loading = true;
      this.appSvc.getAllProjectManagers(this.teamId, this.page.size, this.page.pageNumber).subscribe({next:res => {
        this.userCount = res;
        if (res.data.length > 0) {
          this.userData.emit(true);
        } else {
          this.userData.emit(false);
        }
        this.loading = false;
        this.page.totalElements = res.totalElements;
        this.allUsersList = res.data;
        this.allUsers = res.data;
      //   this.allUsers.forEach(element => {
      //     element.teamUsersDtoList.forEach(ele1 => {
      //     element.executionTeams =ele1.teamName
	    // });});
        this.useUserList = res.data;
        this.selected = [];
        setTimeout(() => {
          this.tableVisible = true;
        }, 0);
      }, error:() => {
        this.loading = false;
      }});
    } else if (this.role === 'projectSupports') {
      this.loading = true;
      this.appSvc.getAllProjectSupports(this.teamId, this.page.size, this.page.pageNumber).subscribe({next:res => {
        this.userCount = res;
        if (res.data.length > 0) {
          this.userData.emit(true);
        } else {
          this.userData.emit(false);
        }
        this.loading = false;
        this.page.totalElements = res.totalElements;
        this.allUsersList = res.data;
        this.allUsers = res.data;
        this.useUserList = res.data;
        this.selected = [];
        setTimeout(() => {
          this.tableVisible = true;
        }, 0);
      }, error:() => {
        this.loading = false;
      }}); 
    } else {
      this.loading = true;
      this.appSvc.getAllUsers(this.page.size, this.page.pageNumber).subscribe({next:res => {
        this.loading = false;
        this.page.totalElements = res.totalElements;
        this.allUsersList = res.data;
        this.allUsers = res.data;
        if(this.rolesService.hasRole(this.rolesService.PROJECT_SUPPORT)){
        const arr2 = this.allUsers.map(x => ({ ...x, isPS: false }));
        this.allUsers = arr2;
        this.allUsers.forEach(eleRole => {
          if(eleRole.userRolesDtoList.length > 0){
            eleRole.userRolesDtoList.forEach(eleRolelist => {
              if(eleRolelist.roleName === this.rolesService.PROJECT_MANAGER) {
                eleRole.isPS = true;
              }
            });
          }

        });
      }
      if(this.rolesService.hasRole(this.rolesService.SYSTEM_SUPPORT)){
        const arr2 = this.allUsers.map(x => ({ ...x, isPS: false }));
        this.allUsers = arr2;
        this.allUsers.forEach(eleRole => {
          if(eleRole.userRolesDtoList.length > 0){
            eleRole.userRolesDtoList.forEach(eleRolelist => {
              if(eleRolelist.roleName === 'System Manager') {
                eleRole.isPS = true;
              }
            });
          }

        });
      }
        this.allUsers.forEach(element => {
          element.teamUsersDtoList.forEach(ele1 => {
          element.executionTeams = ele1.teamName
      });});
        this.useUserList = res.data;
        this.selected = [];
        setTimeout(() => {
          this.tableVisible = true;
        }, 0);
      }, error: () => {
        this.loading = false;
      }});
    }
    if (this.route.url.includes('/dashboard/members-privileges')) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
    if (this.teamUser === true) {
      this.showButton = false;
    }
  }
  ngAfterViewInit() {
    if (this.userRolesObject.roles.toLowerCase() === this.rolesService.SYSTEM_MANAGER || this.userRolesObject.roles.toLowerCase() === this.rolesService.SYSTEM_SUPPORT || this.userRolesObject.roles.toLowerCase() === this.rolesService.ADMIN) {
      const element: HTMLElement = document.getElementById('executionTeams');
      element.hidden = true;
    }
  }

  updateFilter() {
    const strFilter = this.filterStr.trim().toLowerCase();
    let filterItems = this.filterColumns.filter(filterItem => filterItem.selected);
    if (filterItems.length === 0) {
      filterItems = this.filterColumns;
    }
    // filter our data
    this.allUsers = this.allUsersList.filter(user => {
      if (!strFilter) {
        return true;
      }
      return filterItems.some(filterItem => {
        let searchTxt = user[filterItem.prop] || '';
        if (!(user[filterItem.prop] instanceof String)) {
          searchTxt = JSON.stringify(user[filterItem.prop]);
        }
        return searchTxt.toLowerCase().indexOf(strFilter) !== -1;
      });
    });
  }

  onKey(term: string): void {
    this.cancelTimeout();
    this.currentTimeout = setTimeout(() => {
      if (this.filterStr.trim().length !== 0) {


        if (this.teamUser === true) {
          this.loading = true;
          this.appSvc.searchGetAllUsersInTeam(this.teamId, this.page.size, this.filterStr).subscribe({next:res => {
            this.userCount = res;
            if (this.userCount.length > 0) {
              this.userData.emit(true);
            } else {
              this.userData.emit(false);
            }
            this.loading = false;
            this.page.totalElements = res.totalElements;
            this.allUsersList = res.data;
            this.allUsers = res.data;
            const arr2 = this.allUsers.map(x => ({ ...x, key: false }));
            this.allUsers = arr2;
            this.allUsers.forEach(element => {
              this.userIdExist.forEach(element2 => {
                if (element.userId === element2.userId) {
                  element.key = true;
                }
              });
            });
            this.useUserList = res.data;
            this.selected = [];
            setTimeout(() => {
              this.tableVisible = true;
            }, 0);
          }, error: () => {
            this.loading = false;
          }});
        } else if (this.role === 'ABBManager') {
          this.loading = true;
          this.appSvc.searchGetAllProjectUsers(this.teamId, 0, this.filterStr).subscribe({next:res => {
            if (res.data.length > 0) {
              this.userData.emit(true);
            } else {
              this.userData.emit(false);
            }
            this.loading = false;
            this.page.totalElements = res.totalElements;
            this.allUsersList = res.data;
            this.allUsers = res.data;
            this.useUserList = res.data;
            this.selected = [];
            setTimeout(() => {
              this.tableVisible = true;
            }, 0);
          }, error:() => {
            this.loading = false;
          }});
        } else if (this.role === 'projectManagers') {
          this.loading = true;
          this.appSvc.searchGetAllProjectManagers(this.teamId, this.page.size, this.filterStr).subscribe(res => {
            this.userCount = res;
            if (res.data.length > 0) {
              this.userData.emit(true);
            } else {
              this.userData.emit(false);
            }
            this.loading = false;
            this.page.totalElements = res.totalElements;
            this.allUsersList = res.data;
            this.allUsers = res.data;
            this.useUserList = res.data;
            this.selected = [];
            setTimeout(() => {
              this.tableVisible = true;
            }, 0);
          });
        } else if (this.role === 'projectSupports') {
          this.loading = true;
          this.appSvc.searchGetAllProjectSupports(this.teamId, this.page.size, this.filterStr).subscribe({next:res => {
            this.userCount = res;
            if (res.data.length > 0) {
              this.userData.emit(true);
            } else {
              this.userData.emit(false);
            }
            this.loading = false;
            this.page.totalElements = res.totalElements;
            this.allUsersList = res.data;
            this.allUsers = res.data;
            this.useUserList = res.data;
            this.selected = [];
            setTimeout(() => {
              this.tableVisible = true;
            }, 0);
          }, error:() => {
            this.loading = false;
          }});
        } else {
          this.loading = true;
          this.appSvc.searchGetAllUsers(this.teamId, 0, this.filterStr).subscribe({next:res => {
            this.loading = false;
            this.page.totalElements = res.totalElements;
            this.allUsersList = res.data;
            this.allUsers = res.data;
            this.useUserList = res.data;
            if(this.rolesService.hasRole(this.rolesService.PROJECT_SUPPORT)){
              const arr2 = this.allUsers.map(x => ({ ...x, isPS: false }));
              this.allUsers = arr2;
              this.allUsers.forEach(eleRole => {
                if(eleRole.userRolesDtoList.length > 0){
                  eleRole.userRolesDtoList.forEach(eleRolelist => {
                    if(eleRolelist.roleName === this.rolesService.PROJECT_MANAGER) {
                      eleRole.isPS = true;
                    }
                  });
                }
      
              });
            }
            if(this.rolesService.hasRole(this.rolesService.SYSTEM_SUPPORT)){
              const arr2 = this.allUsers.map(x => ({ ...x, isPS: false }));
              this.allUsers = arr2;
              this.allUsers.forEach(eleRole => {
                if(eleRole.userRolesDtoList.length > 0){
                  eleRole.userRolesDtoList.forEach(eleRolelist => {
                    if(eleRolelist.roleName === this.rolesService.SYSTEM_MANAGER) {
                      eleRole.isPS = true;
                    }
                  });
                }
      
              });
            }
            this.selected = [];
            setTimeout(() => {
              this.tableVisible = true;
            }, 0);
          }, error:() => {
            this.loading = false;
          }});
        }
      } else {
        this.ngOnInit();
      }
    }, 700);
  }
  cancelTimeout(): void {
    clearTimeout(this.currentTimeout);
    this.currentTimeout = undefined;
  }
  addOrEditMember(member) {
    const modalRef = this.modalService.open(EditUserComponent, member ? {
      centered: true,
      backdrop: 'static',
      backdropClass: 'full-screen-modal-backdrop',
      windowClass: 'full-screen-modal-window'
    } : {
        centered: true,
        size: 'lg',
        windowClass: 'medium-screen-modal-window'
      });
    if (member) {
      modalRef.componentInstance.memberInput = member.userId;
    }
    modalRef.componentInstance.mode = member ? 'full-screen' : 'as-modal';
    modalRef.result.then(ret => {
      // add new member
      this.loading = true;
      this.appSvc.getAllUsers(this.page.size, this.page.pageNumber).subscribe({next:res => {
        this.loading = false;
        this.page.totalElements = res.totalElements;
        this.allUsersList = res.data;
        this.allUsers = res.data;
        setTimeout(() => {
          this.tableVisible = true;
        }, 0);
      }, error:() => {
        this.modalService.dismissAll();
        this.loading = false;
      }});
      this.selected = [];
      this.updateFilter();
    }, () => { });
  }
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    selected.forEach((item) => {
      if (item.userId !== this.userId && !item.isPS) {
        this.selected.push(item);
      }
    });
  }
 
  delete() {
    const modalRef = this.modalService.open(DeleteAlertModalComponent, { centered: true });
    modalRef.componentInstance.deleteItemName = 'member(s)';
    modalRef.result.then(ret => {
      if (ret === 'delete') {
        const a = [];
        for (let i = 0; i < this.selected.length; i++) {
          a.push(this.selected[i].userId);
        }
        this.loading = true;
        this.appSvc.deleteUser(a).subscribe({next:res => {
          this.allUsersList = _.difference(this.allUsersList, this.selected);
          const modalRef1 = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef1.componentInstance.title = 'Message';
          if(res.statusCode == 422 ) {
            modalRef1.componentInstance.message = res.errorMessage;
          } else {
          modalRef1.componentInstance.message = modalConstant.Member.deleted;
          this.appSvc.getAllUsers(this.page.size, this.page.pageNumber).subscribe({next:resp => {
            this.loading = false;
            this.page.totalElements = resp.totalElements;
            this.allUsersList = resp.data;
            this.allUsers = resp.data;
            setTimeout(() => {
              this.tableVisible = true;
            }, 0);
          }, error:() => {
            this.modalService.dismissAll();
            this.loading = false;
          }});
          }
          this.loading = false;
        }, error: (err) => {
          this.loading = false;
        }});
        this.selected = [];
        this.updateFilter();
      }
    }, () => { });
  }


  setPage($event) {
    this.page.pageNumber = $event.offset;
    this.ngOnInit();
  }

  hasPermissionToEdit() {
    return this.rolesService.hasPermissionToEdit() || this.rolesService.hasRole(this.rolesService.ADMIN);
  }

}

