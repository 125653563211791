import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-custom-slider-range',
	templateUrl: './custom-slider-range.component.html',
	styleUrl: './custom-slider-range.component.scss'
})
export class CustomSliderRangeComponent implements OnInit, AfterViewInit {
	
	@ViewChild('rangeSlider') rangeSlider!: ElementRef;
	
	@Input()rangeSliderMinVal:number = 0;
	@Input()rangeSliderMaxVal:number = 100;

    @Output()changingMinVal  = new EventEmitter<number>();
	@Output()changingMaxVal  = new EventEmitter<number>()

	constructor(private ngZone: NgZone) { }
	ngOnInit(): void {
	}

	ngAfterViewInit() {
		const container = this.rangeSlider.nativeElement;

		/** Default config */
		const rangeSlider_min = this.rangeSliderMinVal;
		const rangeSlider_max = this.rangeSliderMaxVal;

		/** Default config */
		container.querySelector('#RangeSlider .range-slider-val-left').style.width = `${rangeSlider_min + (100 - rangeSlider_max)}%`;
		container.querySelector('#RangeSlider .range-slider-val-right').style.width = `${rangeSlider_min + (100 - rangeSlider_max)}%`;

		container.querySelector('#RangeSlider .range-slider-val-range').style.left = `${rangeSlider_min}%`;
		container.querySelector('#RangeSlider .range-slider-val-range').style.right = `${(100 - rangeSlider_max)}%`;

		container.querySelector('#RangeSlider .range-slider-handle-left').style.left = `${rangeSlider_min}%`;
		container.querySelector('#RangeSlider .range-slider-handle-right').style.left = `${rangeSlider_max}%`;

		container.querySelector('#RangeSlider .range-slider-tooltip-left').style.left = `${rangeSlider_min}%`;
		container.querySelector('#RangeSlider .range-slider-tooltip-right').style.left = `${rangeSlider_max}%`;

		container.querySelector('#RangeSlider .range-slider-tooltip-left .range-slider-tooltip-text').innerText = rangeSlider_min;
		container.querySelector('#RangeSlider .range-slider-tooltip-right .range-slider-tooltip-text').innerText = rangeSlider_max;

		container.querySelector('#RangeSlider .range-slider-input-left').value = rangeSlider_min;
		container.querySelector('#RangeSlider .range-slider-input-left').addEventListener('input', e => {
			e.target.value = Math.min(e.target.value, e.target.parentNode.childNodes[2].value - 1);
			var value = (100 / (parseInt(e.target.max) - parseInt(e.target.min))) * parseInt(e.target.value) - (100 / (parseInt(e.target.max) - parseInt(e.target.min))) * parseInt(e.target.min);

			var children = e.target.parentNode.childNodes[0].childNodes;
			children[0].style.width = `${value}%`;
			children[2].style.left = `${value}%`;
			children[3].style.left = `${value}%`;
			children[5].style.left = `${value}%`;

			children[5].childNodes[0].innerHTML = e.target.value;
			this.changingMinVal.emit(e.target.value);

			if (e.target.value >= 0 && 33 >= e.target.value) {
				children[5].style.backgroundColor = '#478B1A';
				children[5].childNodes[0].style.backgroundColor = '#478B1A';
				children[5].childNodes[0].style.color ='#FFFFFF';
				container.querySelector('#RangeSlider .range-slider-tooltip-left').classList.remove('range-slider-tooltip-color-black',
					'range-slider-tooltip-color-red', 'range-slider-tooltip-color-yellow');
				container.querySelector('#RangeSlider .range-slider-tooltip-left').classList.add('range-slider-tooltip-color-green');
			}
			else if (e.target.value > 33 && 66 >= e.target.value) {
				children[5].style.backgroundColor = '#FFD966'; //yellow
				children[5].childNodes[0].style.backgroundColor = '#FFD966'; //yellow
				children[5].childNodes[0].style.color ='#414141';
				container.querySelector('#RangeSlider .range-slider-tooltip-left').classList.remove('range-slider-tooltip-color-black',
					'range-slider-tooltip-color-red', 'range-slider-tooltip-color-green');
				container.querySelector('#RangeSlider .range-slider-tooltip-left').classList.add('range-slider-tooltip-color-yellow');
			}
			else if (e.target.value > 66 && 100 >= e.target.value) {
				children[5].style.backgroundColor = '#FF0026';
				children[5].childNodes[0].style.backgroundColor = '#FF0026';
				children[5].childNodes[0].style.color ='#FFFFFF';
				container.querySelector('#RangeSlider .range-slider-tooltip-left').classList.remove('range-slider-tooltip-color-black',
					'range-slider-tooltip-color-yellow', 'range-slider-tooltip-color-green');
				container.querySelector('#RangeSlider .range-slider-tooltip-left').classList.add('range-slider-tooltip-color-red');
			}
			else {
				children[5].style.backgroundColor = 'black';
				children[5].childNodes[0].style.backgroundColor = 'black';
				children[5].childNodes[0].style.color ='#FFFFFF';
				container.querySelector('#RangeSlider .range-slider-tooltip-left').classList.remove('range-slider-tooltip-color-yellow',
					'range-slider-tooltip-color-red', 'range-slider-tooltip-color-green');
				container.querySelector('#RangeSlider .range-slider-tooltip-left').classList.add('range-slider-tooltip-color-black');

			}
		});

		container.querySelector('#RangeSlider .range-slider-input-right').value = rangeSlider_max;
		container.querySelector('#RangeSlider .range-slider-input-right').addEventListener('input', e => {

			e.target.value = Math.max(e.target.value, e.target.parentNode.childNodes[1].value - (-1));
			var value = (100 / (parseInt(e.target.max) - parseInt(e.target.min))) * parseInt(e.target.value) - (100 / (parseInt(e.target.max) - parseInt(e.target.min))) * parseInt(e.target.min);
			var children = e.target.parentNode.childNodes[0].childNodes;
			children[1].style.width = `${100 - value}%`;
			children[2].style.right = `${100 - value}%`;
			children[4].style.left = `${value}%`;
			children[6].style.left = `${value}%`;

			children[6].childNodes[0].innerHTML = e.target.value;
             this.changingMaxVal.emit(e.target.value);

			if (e.target.value >= 0 && 33 >= e.target.value) {
				children[6].style.backgroundColor = '#478B1A';
				children[6].childNodes[0].style.backgroundColor = '#478B1A';
				children[6].childNodes[0].style.color ='#FFFFFF';
				container.querySelector('#RangeSlider .range-slider-tooltip-right').classList.remove('range-slider-tooltip-color-black',
					'range-slider-tooltip-color-red', 'range-slider-tooltip-color-yellow');
				container.querySelector('#RangeSlider .range-slider-tooltip-right').classList.add('range-slider-tooltip-color-green');
			}
			else if (e.target.value > 33 && 66 >= e.target.value) {
				children[6].style.backgroundColor = '#FFD966'; //yellow
				children[6].childNodes[0].style.backgroundColor = '#FFD966';  //yellow
				children[6].childNodes[0].style.color ='#414141';
				container.querySelector('#RangeSlider .range-slider-tooltip-right').classList.remove('range-slider-tooltip-color-black',
					'range-slider-tooltip-color-red', 'range-slider-tooltip-color-green');
				container.querySelector('#RangeSlider .range-slider-tooltip-right').classList.add('range-slider-tooltip-color-yellow');
			}
			else if (e.target.value > 66 && 100 >= e.target.value) {
				children[6].style.backgroundColor = '#FF0026';
				children[6].childNodes[0].style.backgroundColor = '#FF0026';
				children[6].childNodes[0].style.color ='#FFFFFF';
				container.querySelector('#RangeSlider .range-slider-tooltip-right').classList.remove('range-slider-tooltip-color-black',
					'range-slider-tooltip-color-yellow', 'range-slider-tooltip-color-green');
				container.querySelector('#RangeSlider .range-slider-tooltip-right').classList.add('range-slider-tooltip-color-red');

			}
			else {
				children[6].style.backgroundColor = 'black';
				children[6].childNodes[0].style.backgroundColor = 'black';
				children[6].childNodes[0].style.color ='#FFFFFF';
				container.querySelector('#RangeSlider .range-slider-tooltip-right').classList.remove('range-slider-tooltip-color-yellow',
					'range-slider-tooltip-color-red', 'range-slider-tooltip-color-green');
				container.querySelector('#RangeSlider .range-slider-tooltip-right').classList.add('range-slider-tooltip-color-black');

			}
		});

	}
}
