import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatListOption } from '@angular/material/list';
import { ApplicationService } from '../../../services/application.service';
import { FormControl, FormGroup,FormBuilder } from '@angular/forms';

@Component({
  selector: 'csp-common-filter',
  templateUrl: './csp-common-filter.component.html',
  styleUrls: ['./csp-common-filter.component.scss'],
})
export class CspCommonFilterComponent implements OnInit {
  @ViewChild('filterTemplate') filterTemplate!: TemplateRef<any>;
  @ViewChild('search1') searchInput!: ElementRef;
  checkedValues: any[] = [];
  selectedOptions: any;
  selectedList: any = {};
  multipleSelectedOptions: any;
  selectedValue: any;
  selectedFinalArray: any = {};
  searchedText:any
  @Input() isPopup: string;
  @Input() spareGroupFilterData:any
  @Input() tabName: any;
  @Input() totalOptionLength: any;
  @Input() filterInput: any;
  @Input() finalValue!: any[];
  @Input() isFullScreen = false;
  @Input() alwaysShowFilterPillBoxes: boolean;
  @Input() ActualFilterInput: any;
  @Input() projectId: any;
  @Input() filterAPICallType: string;
  @Input() filterStyles:any={'button':15, 'space': 75 , 'search': 10};
  // do the pill boxes for applied filters show only field names or values also
  @Input() pillBoxSpecificity: 'values' | 'fields' = 'fields';

  @Output() expandFilterView = new EventEmitter();
  @Output()  filterItemEmit = new EventEmitter();
  isOpen = false;
  filterItem: any;
  rightSelectedValues: any = {};
  mockSelectedList: any = {};
  masterData2 = [];
  loading = false;
  colorList = [
    '#808080',
    '#808080',
    '#808080',
    '#808080',
    '#808080',
    '#808080',
    '#808080',
  ];
  @Output() filterDataEmitter = new EventEmitter();
  finalList: string[] = [];
  multiFinalList: any[] = [];
  totalLength: number = 0;
  isOptionSelected=false;


  dateRangeOptions = new FormControl('');
  dateRangeOptionlist: string[] = ['Due','Assign','Schedule','Scheduled','Excution','Excuted','Completed'];
  isCustomRange: boolean = false;
  startDate: string | null = null;
  endDate: string | null = null;
  form: FormGroup; 
  selectedOptionsDropdown: number[] = [];
  isDropdownOpen = false;
  dateRangeSeleaction: any = {"1":false,"7":false,"28":false,"90":false,"365":false,"custom":false};
  riskSliderMinVal: number = 0;
  riskSliderMaxVal: number =  100;
  prioritySliderMinVal: number = 0;
  prioritySliderMaxVal: number = 100;

  constructor(private appSvc: ApplicationService,private fb: FormBuilder) {}
 @Input() filterOptions = [
    {
      key: 'EquipmentCategory',
      value: 'Equipment Category',
    }
  ];

@Input() multipleFilterOptions = [
    {
      menuId: 'EquipmentCategory',
      menuValues: {
        '1acd': 'Active',
        '2sca': 'Classic',
        '3sve': 'Limited',
        '4dav': 'Obsolete',
        '1sVfeE': 'Active EquipmentCategory',
        '2eegsedv': 'Classic EquipmentCategory',
        '3drgssb': 'Limited EquipmentCategory',
        '4sgrb': 'Obsolete EquipmentCategory',
      },
    }
  ];
  dictionary:any={}
  multiDictionary:any={}

  filteredValues: any;
  filterNewProperty : any[] = ["RiskLevel","TaskPriority","DateRange","OverDueTasks","States"];
  showSearchBox:boolean = true;
  selectedOverDueMoreThen:string | any = "0";
  selectedOverDueLessThen:string | any = "0";
  selectedOverDueCondition: any;
  selectedOverDueValue:any;
  ngOnInit(): void {
    if(this.filterOptions){
  this.dictionary = Object.fromEntries(
    this.filterOptions?.map(({ key, ...rest }) => [key, rest])
  );
    }
    if(this.multipleFilterOptions){
  this.multiDictionary = Object.fromEntries(
    this.multipleFilterOptions.map(({ menuId, ...rest }) => [menuId, rest])
  );
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.filterOptions){
    this.dictionary = Object.fromEntries(
      this.filterOptions?.map(({ key, ...rest }) => [key, rest])
    );
    }if(this.multipleFilterOptions){
    this.multiDictionary = Object.fromEntries(
      this.multipleFilterOptions.map(({ menuId, ...rest }) => [menuId, rest])
    );
    }
    for (const propName in changes) {
      if (propName === 'filterInput') {
        this.rightSelectedValues = { ...this.filterInput?.filterReq };
        this.finalValue = { ...this.rightSelectedValues };
        for (var propName1 in this.finalValue) {
          if (this.multiDictionary[propName1] === undefined) {
            delete this.finalValue[propName1];
            delete this.rightSelectedValues[propName1];
          }
          if (this.multiDictionary[propName1] !== undefined) {
            this.rightSelectedValues[propName1] = this.finalValue[propName1] =
              Object.values(this.multiDictionary[propName1]?.menuValues).filter(
                (x: any) => {
                  return x === this.finalValue[propName1][0];
                }
              );
            if (
              this.finalValue[propName1] === null ||
              this.finalValue[propName1].length === 0
            ) {
              delete this.finalValue[propName1];
              delete this.rightSelectedValues[propName1];
            }
          }
        }
        this.finalList = Object.keys(this.finalValue);
        this.multiFinalList = Object.values(this.finalValue);
        this.totalLength = this.multiFinalList.filter(x => x.length).length;
      }
    }

    if (this.finalValue) {
      const originallyAppliedFilterMenuItemsCount = Object.keys(this.finalValue).length;

      const appliedFiltersToRemove = [];
      Object.keys(this.finalValue).forEach(prop => {
        if (this.filterOptions.map(opt => opt.key).indexOf(prop) === -1) {
          appliedFiltersToRemove.push(prop);
        }
      });
      appliedFiltersToRemove.forEach(prop => {
        this.removeFieldFromFilter(prop, true);
      });

      Object.keys(this.finalValue).forEach(prop => {
        const appliedValues = this.finalValue[prop];
        const newOptionValues = this.multipleFilterOptions.find(opt => opt.menuId === prop)?.menuValues || [];
        const appliedFilterValuesToRemove = appliedValues.filter(val => !(newOptionValues as any[]).includes(val));
        appliedFilterValuesToRemove.forEach(val => {
          this.removeValueFromFilter(prop, val, true);
        });
      });

      const newAppliedFilterMenuItemsCount = Object.keys(this.finalValue).length;

      if (newAppliedFilterMenuItemsCount != originallyAppliedFilterMenuItemsCount) {
        this.sendDataToFilter(this.finalValue);
      }
    }
  }

  setDateRange(range) {
    this.isCustomRange = false;
    // Logic to handle predefined date ranges
    if(range == 'custom'){
      this.isCustomRange = true;
    }
    else{
          const dates =  this.getStartAndEndDate(range);
          this.startDate = dates.startDate;
          this.endDate = dates.endDate;
    }
   //active the div, based on selection
    this.activeDataSelection(range);
  }


  closeDialog() {
    this.isOpen = false;
    this.isOptionSelected=false
  }

  search(value: any) {
    let filter:any={}
    Object.keys(this.mockSelectedList).map((item: any) =>
    this.mockSelectedList[item] != null? (this.mockSelectedList[item].toLowerCase().includes(value.toLowerCase()) ? filter[item]=this.mockSelectedList[item] : ''): ''
    );
    this.selectedList = filter;
    return this.selectedList;
  }
  onSelection(event: any, selectedOptions: any) {
    this.isOptionSelected=true
    this.searchInput.nativeElement.value = '';
    this.selectedValue = selectedOptions;
    this.showSearchBox = this.filterNewProperty.includes(this.selectedValue.trim()) ? false : true;
    this.selectedList = this.multipleFilterOptions.filter((x: any) => {
      return x['menuId'] === this.selectedValue;
    })[0]
      ? this.multipleFilterOptions.filter((x: any) => {
          return x['menuId'] === this.selectedValue;
        })[0]['menuValues']
      : [];
    this.mockSelectedList = {...this.selectedList};
  }

  onMultipleSelection(event: any, selectedOptions: any, selectedcolumn: any) {
    this.checkedValues = [];
    this.selectedFinalArray[selectedcolumn] = null;
    this.multipleSelectedOptions = selectedOptions;
    for (let i = 0; i < this.multipleSelectedOptions.length; i++) {
      this.checkedValues.push(this.multipleSelectedOptions[i]._value);
    }
    this.selectedFinalArray[selectedcolumn] = this.checkedValues;
    this.rightSelectedValues = { ...this.selectedFinalArray };
  }

  applyFilter() {
    //get the new filter data and assign to API 
    this.getNewFilterValuesAndAssignToApi();
    this.finalValue = { ...this.rightSelectedValues };
    for (var propName in this.finalValue) {
      if (this.finalValue[propName] === null || this.finalValue[propName].length === 0) {
        delete this.finalValue[propName];
      }
    }
    this.finalList = Object.keys(this.finalValue);
    this.multiFinalList = Object.values(this.finalValue);
    this.totalLength = this.multiFinalList.filter(x => x.length).length;
    this.sendDataToFilter(this.finalValue)
    this.isOpen = false;
    this.isOptionSelected=false
  }

  toggleOverlay() {
    this.rightSelectedValues = { ...this.finalValue };
    this.selectedFinalArray = { ...this.finalValue };
    this.selectedList={}
    this.isOpen = true;
    if(!this.isFullScreen) {
      this.expandFilterView.emit();
    }
  }
  getRandomColor(index: any) {
    if (index < this.colorList.length) {
      return this.colorList[index];
    } else {
      return this.colorList[index - this.colorList.length];
    }
  }

  // remove a specific value from the applied filters
  removeValueFromFilter(option: any, value: string, silent?: boolean) {
    // if only one value has been set for this filed, we basically need to remove the entire field
    if (this.finalValue[option].length === 1) { return this.removeFieldFromFilter(option, silent); }

    this.finalValue[option].splice(this.finalValue[option].indexOf(value), 1);
    this.sendDataToFilter(this.finalValue);
  }

  // remove an entire filter field from applied filters
  removeFieldFromFilter(option: any, silent?: boolean) {
    this.finalList = this.finalList.filter((x: any) => {
      return x !== option;
    });
    delete this.finalValue[option];
    this.multiFinalList = Object.values(this.finalValue);
    this.totalLength = this.multiFinalList.filter(x => x.length).length;
    if (!silent) {
      this.sendDataToFilter(this.finalValue)
    }
  }

  clearFilter() {
    this.rightSelectedValues = {};
    this.selectedFinalArray = {};
  }

  getObjectLength(key: any) {
    if (this.multiDictionary[key]?.menuValues) {
      return Object.keys(this.multiDictionary[key]?.menuValues).length;
    }
    return 0;
  }

  isEllipsisActive(e:MatListOption): boolean {
    var element=(e['_element'] as ElementRef).nativeElement['__ngContext__'][13][24]
    return element ? element.offsetWidth < element.scrollWidth : false;
  }
  sendSearchData(value:any){
    this.searchedText=value
    this.sendDataToFilter(this.finalValue)
  }

        // Removing all empty array filter if no filter selected to get all records
  mainFilterCount(req){
        let flag = 0;
        Object.keys(req).forEach(key =>{
          if(req[key].length > 0)
                flag++;
        });
    return flag;
  }
  sendDataToFilter(data:any){
    let req:any={}
    if(data !== undefined){
    this.multipleFilterOptions.map((item:any)=>{
     req[item.menuId]= data[item.menuId]?.length>0 ? data[item.menuId] : [];
    })
    if(req['Linked']?.length>0){
      req['Linked']=req['Linked']?.map((e:any) => e === 'true')
    }
  }
  else{
    req=null
  }
    let reqObject={
      filterReq: req,
      searchData: this.searchedText,
      apiCall: this.isPopup
    }
    this.filterItem = {
      filterListCount:this.totalOptionLength,
      compFilterList: this.multipleFilterOptions,
      mainFilterList:this.filterOptions,
      filterDataList:req
    }
    this.filterItemEmit.emit(this.filterItem);
    // this.appSvc.setfilterData(this.filterItem);
    this.appSvc.sendFilterList(reqObject);
    this.filterDataEmitter.emit(reqObject)
  }
  getSelectedValues(key:any,selectedValue:any){
  return this.rightSelectedValues[selectedValue] ? this.rightSelectedValues[selectedValue].indexOf(key) !== -1 : false
  }

  getErrorNoRecords(){
    if(Object.keys(this.selectedList).length <= 0 && this.isOptionSelected){
    return true
    }
    return false
  }

  removeSelectedItem(selectedItem) {
    const selectedValues: any = this.dateRangeOptions.value;
    const updatedValues = selectedValues.filter((value: string) => value !== selectedItem);
    this.dateRangeOptions.setValue(updatedValues);
  }

  activeDataSelection(selectedVal) {
    Object.keys(this.dateRangeSeleaction).forEach(key => {
      if (key === selectedVal) {
        this.dateRangeSeleaction[key] = true; // Increment age by 1
      }
      else {
        this.dateRangeSeleaction[key] = false;
      }
    });
  }

  getPriorityMinVal(val: number) {
    this.prioritySliderMinVal = val;
  }

  getPriorityMaxVal(val: number) {
    this.prioritySliderMaxVal = val;
  }

  getRiskMinVal(val: number) {
    this.riskSliderMinVal = val;
  }

  getRiskMaxVal(val: number) {
    this.riskSliderMaxVal = val;
  }

  getNewFilterValuesAndAssignToApi() {
    //Risk levels
    if (this.riskSliderMinVal != 0 || this.riskSliderMaxVal != 100) {
      this.rightSelectedValues.RiskLevel = [{ "min": this.riskSliderMinVal, "max": this.riskSliderMaxVal }];
    }
    //Priority 
    if (this.prioritySliderMinVal != 0 || this.prioritySliderMaxVal != 100) {
      this.rightSelectedValues.Priority = [{ "min": this.prioritySliderMinVal, "max": this.prioritySliderMaxVal }];
    }
    //date range 
    if (this.dateRangeOptions.value.length > 0) {
      const sdate = new Date(this.startDate).toLocaleString('en-GB', { year: 'numeric',month: '2-digit', day: '2-digit'});
      const edate = new Date(this.endDate).toLocaleString('en-GB', { year: 'numeric',month: '2-digit', day: '2-digit'});
      this.rightSelectedValues.DateRange = [this.dateRangeOptions.value, [{ "startDate": sdate, "endDate": edate}]];
    }
    //Over due seletion
   if(this.selectedOverDueValue != 0 && this.selectedOverDueCondition){
    this.rightSelectedValues.OverDueTasks = [{"condition": this.selectedOverDueCondition,"days": this.selectedOverDueValue}];          
   }
  }

  getStartAndEndDate(days) {
    // Get the current date and time
    const startDate = new Date();
    // Create a new date object for the end date and time
    const endDate = new Date();
    endDate.setDate(startDate.getDate() - days);
    // Format the dates and times as strings (optional)
    const options: any = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formattedStartDate = startDate.toLocaleString('en-GB', options);
    const formattedEndDate = endDate.toLocaleString('en-GB', options);
    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };
 }

 selectedOverDue(event,selectedCondition){
   this.selectedOverDueCondition = selectedCondition;
   this.selectedOverDueValue = event;
   selectedCondition == 'moreThen' ? this.selectedOverDueLessThen = "0" : this.selectedOverDueMoreThen = "0";
 }

}
