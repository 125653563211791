import { Component, EventEmitter, HostListener, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../../../../../services/notification.service';
import { RolesService } from 'src/services/roles.service';

@Component({
    selector: 'app-notification-settings',
    templateUrl: './notification-settings.component.html',
    styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {
    manualRefresh: EventEmitter<void> = new EventEmitter<void>();
    @Input() title = '';
    submitted = false;
    loading = false;
    isClose = false;
    tasks = false;
    performance = false;
    systemAndAccount = false;
    public emailPopup = false;
    public emailOptions = [];
    public openSide = false;
    public data = {
        tasks: {
            enableNewTasks: false,
            enableOverdueTasks: false,
            enableRejectedTasks: false,
            enableValidatedTasks: false,
            email: {
                enable: false,
                option: 'Once an hour'
            }
        },
        performance: {
            enableSubstationRiskLevels: false,
            enableAssetRiskLevels: false,
            email: {
                enable: false,
                option: 'Once an hour'
            }
        },
        systemAndAccount: {
            enableAccountUpdates: false,
            enableSystemEvents: false,
            email: {
                enable: false,
                option: 'Once an hour'
            }
        }
    };
    public isAboutPanelOpen = true;
    public isThroughPanelOpen = true;
    public activeDropdown: string | null = null;
    constructor(
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private notificationSvc: NotificationService,
        public rolesService: RolesService
    ) { }

    ngOnInit() {
        this.notificationSvc.getEmailSettingOptions().subscribe(res => {
            this.emailOptions = res.returnObj;
        });
        this.notificationSvc.getNotificationSettings().subscribe(data => {
            this.data = data.returnObj;
            this.tasks = this.data.tasks.email.enable;
            this.performance = this.data.performance.email.enable;
            this.systemAndAccount = this.data.systemAndAccount.email.enable;
        });
    }

    onSubmit() {
        this.submitted = true;
        const setDefaultEmailOption = (section, defaultOption = "Once an hour") => {
            if (this.data[section].email.enable) {
                if (this.data[section].email.option == null) {
                    this.data[section].email.option = defaultOption;
                }
            } else {
                this.data[section].email.option = "";
                this.data[section].email.enable = false;
            }
        };
        setDefaultEmailOption('tasks');
        setDefaultEmailOption('performance');
        setDefaultEmailOption('systemAndAccount');
        const setBooleanDefaults = (section, fields) => {
            fields.forEach(field => {
                if (this.data[section][field] == null) {
                    this.data[section][field] = false;
                }
            });
        };
        setBooleanDefaults('tasks', ['enableNewTasks', 'enableOverdueTasks', 'enableRejectedTasks', 'enableValidatedTasks']);
        setBooleanDefaults('performance', ['enableAssetRiskLevels', 'enableSubstationRiskLevels']);
        setBooleanDefaults('systemAndAccount', ['enableAccountUpdates', 'enableSystemEvents']);

        this.activeModal.close(this.data);
        this.notificationSvc.updateNotificationSettings(this.data).subscribe(() => {
            this.toastr.success('Successfully saved');
        });
    }

    clickedInside($event: Event, dropdownId: string) {
        $event.preventDefault();
        $event.stopPropagation();
        this.activeDropdown = this.activeDropdown === dropdownId ? null : dropdownId;
        const enableTasks = this.data.tasks.email;
        const enablePerformance = this.data.performance.email;
        const enableAccount = this.data.systemAndAccount.email;

        if (enableTasks || enablePerformance || enableAccount) {
            this.emailPopup = !this.isClose;
            this.isClose = false;
        }
        else {
            this.isClose = true;
        }

    }

    @HostListener('document:click', ['$event'])
    clickedOutside() {
        this.emailPopup = false;
        this.activeDropdown = null;
    }

    changeEmailOption(item, dropdownId) {
        switch (dropdownId) {
            case 'tasksDropdown':
                this.data.tasks.email.option = item;
                break;
            case 'performanceDropdown':
                this.data.performance.email.option = item;
                break;
            case 'accountDropdown':
                this.data.systemAndAccount.email.option = item;
                break;
        }

        this.activeDropdown = null;
        this.openSide = false;
        this.emailPopup = false;
        this.isClose = true;
    }
    onChangeChecked(e, type, name) {
        this.data[type][name] = e.target.checked;
        this.manualRefresh.emit();
    }

    onChangeTasksChecked(event: Event, type: string, name: string) {
        this.tasks = (event.target as HTMLInputElement).checked;
        this.data.tasks.email.enable = this.tasks;
        this.manualRefresh.emit();
    }
    onChangePerformanceChecked(event: Event, type: string, name: string) {
        this.performance = (event.target as HTMLInputElement).checked;
        this.data.performance.email.enable = this.performance;
        this.manualRefresh.emit();
    }
    onChangeAccountChecked(event: Event, type: string, name: string) {
        this.systemAndAccount = (event.target as HTMLInputElement).checked;
        this.data.systemAndAccount.email.enable = this.systemAndAccount;
        this.manualRefresh.emit();
    }

    getStatus(type, name) {
        if (_.has(this.data, type)) {
            if (_.has(this.data[type], name)) {
                return this.data[type][name];
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    clickThroughPanel() {
        this.isThroughPanelOpen = !this.isThroughPanelOpen;
    }

    clickAboutPanel() {
        this.isAboutPanelOpen = !this.isAboutPanelOpen;
    }

    isSystemRole() {
        return this.rolesService.isSystemRole();
    }

}
